<template>
  <div v-if="ready">
    <b-row class="pb-3">
      <b-col md="3">
        <card :title="'Enviados Hoje'">
          <div class="py-3" slot="card-body">
            <h4>
              <strong class="text-info">{{
                data.total_sent_day[0].total
              }}</strong>
            </h4>
            <small class="text-muted mt-auto">no mês atual</small>
          </div>
        </card>
      </b-col>
      <b-col md="3">
        <card :title="'Aguardando Envio'" class="h-100">
          <div class="py-3" slot="card-body">
            <h4>
              <strong class="text-success">{{
                data.total_waiting_month[0].total
              }}</strong>
            </h4>
          </div>
        </card>
      </b-col>

      <b-col md="3">
        <card :title="'Erros no envio'" class="h-100">
          <div class="py-3" slot="card-body">
            <h4>
              <strong class="text-info">{{
                data.total_error_month[0].total
              }}</strong>
            </h4>
          </div>
        </card>
      </b-col>

      <b-col md="3">
        <card :title="'Enviados no Mes'">
          <div class="py-3" slot="card-body">
            <h4>
              <strong class="text-danger">{{
                data.total_sent_month[0].total
              }}</strong
              ><!----><!----><!----><!----><!---->
            </h4>
            <small class="text-muted mt-auto">no mês atual</small>
          </div>
        </card>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="9">
        <Card :title="'Indicador Anual'" class="pb-4 h-100">
          <canvas id="myChart" width="400" height="122"></canvas>
        </Card>
      </b-col>
      <b-col md="3">
        <Card :title="'Indicador Mensal'" class="pb-4 h-100">
          <canvas id="donut" width="100" height="80"></canvas>
        </Card>
      </b-col>
    </b-row>
  </div>
</template>


<script>
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";
Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);
export default {
  data() {
    return {
      line_chart: "",
      donut_chart: "",
      ready: false,
    };
  },
  watch: {
    data: {
      handler() {
        setTimeout(() => {
          this.create_donut();
          this.create_chart_annual()
        }, 1000);
      },
    },
  },
  computed: {
    data() {
      return this.$store.state.dashboard.data;
    },
    annualData() {
      const data = this.data.all_month;
      const grouped = _.groupBy(data, "month");
      return grouped;
    },
  },
  methods: {
    donutData() {
      const received = this.data.current_month[0].status_sent;

      const pending = this.data.current_month[0].status_waiting;

      const error = this.data.current_month[0].status_error;

      const data = {
        labels: ["Enviados", "Aguardando Envio", "Erros"],
        datasets: [
          {
            data: [received, pending, error],
            label: "Lançamentos",
            backgroundColor: ["#3cba9f", "#ffc04d", "#dc3545"],
          },
        ],
      };

      return data;
    },
    getData() {
      const labels = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];

      let waiting = [];
      let error = [];
      let sent = [];
      // let pending = [];

      _.forEach(labels, (month, index) => {
        const currentMonth = (index + 1).toString();

        const values = this.annualData[currentMonth];

        if (values) {
          waiting.push(Number(values[0].status_waiting));
          error.push(Number(values[0].status_error));
          sent.push(Number(values[0].status_sent));
          // pending.push(Number(values[0].qtd_pending));
        } else {
          waiting.push(0);
          error.push(0);
          sent.push(0);
          // pending.push(0);
        }
      });

      // waiting.push(Math.max(...waiting, ...error, ...sent, ...pending) + 2); // inserido para poder fazer a curva no grafico
      const data = {
        labels,
        datasets: [
          // {
          //   data: pending,
          //   label: "Pendentes",
          //   borderColor: "#ffc04d",
          //   backgroundColor: "#ffc04d",
          //   tension: "0.4",
          // },
          {
            data: sent,
            label: "Enviados",
            borderColor: "#71d1bd",
            backgroundColor: "#71d1bd",

            tension: "0.4",
          },
          {
            data: waiting,
            label: "Aguardando",
            borderColor: "#7bb6dd",
            backgroundColor: "#7bb6dd",

            tension: "0.4",
          },
          {
            data: error,
            label: "Erros",
            borderColor: "#dc3545",
            backgroundColor: "#dc3545",

            tension: "0.4",
          },
        ],
      };
      return data;
    },
    create_chart_annual() {
      try{

        this.line_chart.destroy()
      }catch(e){}
      const ctx = document.getElementById("myChart");

      const data = this.getData();

      this.line_chart = new Chart(ctx, {
        type: "line",
        data,
        options: {
          animations: {
            radius: {
              duration: 400,
              easing: "linear",
              // loop: (context) => context.active,
            },
          },
          hoverRadius: 6,
          // hoverBackgroundColor: "yellow",
          interaction: {
            mode: "nearest",
            intersect: false,
            axis: "x",
          },
          plugins: {
            tooltip: {
              enabled: true,
            },
          },
        },
      });
    },
    create_donut() {
      try{

        this.donut_chart.destroy()
      }catch(e){}
      const canvas = document.getElementById("donut");
      const data1 = this.donutData();
      this.donut_chart = new Chart(canvas, {
        type: "doughnut",
        data: data1,
        // options: {
        //   scales: {
        //     y: {
        //       beginAtZero: true,
        //     },
        //   },
        // },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
        },
      });
    },
  },
  async mounted() {
    await this.$store.dispatch("dashboard/init");

    this.ready = true;

    setTimeout(() => {
      this.create_chart_annual();
      this.create_donut();
    }, 500);
  },
};
</script>

